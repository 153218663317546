import React, { useRef, useEffect, useState, useMemo, useContext } from "react";
// import provideKeywords from "../../services/provideKeywords";
import { provideKeywords } from "../../services/ProvideDataObjects";
import { MainContext } from "../MainContext";



const KeyWordContainer = ({destinations}) => {
    const { userData } = useContext(MainContext);
    const [relevantKeywords, setRelevantKeywords] = useState([]);
    const chosenDestinations = useRef(undefined);
    const lastCalled = useRef(0);
    const keywordsTimeout = useRef(null);
    const queuedDestinationsToQueryKeywords = useRef([]);

    useEffect( () => {  
        if (destinations?.length > 0 &&
        destinations?.length !== chosenDestinations.current?.length) {
            /**
             * In case of new destinations found, they will be stored
             * in @newDestinations and corresponding keywords will be
             * queried
             */
            // In case of new destinations we have to collect
            // new ones in @newDestinations and pass them to Notion API 
            // to get corresponding keywords
            let newDestinations = undefined;

            if (chosenDestinations.current !== undefined) {
                
                if (destinations.length < chosenDestinations.current.length) {
                    // in case of user deleting previously
                    // chosen destinations we have to remove them from
                    // @relevantKeywords state 
                    chosenDestinations.current = destinations;
                    let reducedKeywords = checkKeywordsOnDestinations(relevantKeywords)
                    setRelevantKeywords(reducedKeywords);
                } if (destinations.length > chosenDestinations.current.length) {
                    // in case of user adding new desinations 
                    // to already chosen we have to filtrer out 
                    // destinations with known keywords 
                    newDestinations = destinations.filter(dest => !chosenDestinations.current.includes(dest))
                }
            } else {
                // in case of first added destination
                newDestinations = destinations
            }
            
            chosenDestinations.current = destinations; 
            /**
             * Set destinations stored in @chosenDestinations reference array
             * to stay always relevant, not depending on react render cycle.
             * Last but not least, fetching keywords for new destinations
             * from backend, process them and set new @relevantKeywords state
             * inside @keywordsHandler function.
             */
            newDestinations !== undefined && keywordsHandler(newDestinations, 1000) 

        } if (destinations?.length < 1) {
            /**
             * if all destinations removed, @relevantKeywords state and 
             * @chosenDestinations ref set to initial values.
             */
            setRelevantKeywords([])
            chosenDestinations.current = undefined;
        }
    }, [destinations])

    const checkKeywordsOnDestinations = (keywords) => {
        // function returns keywords only in case thier "area" property,
        // containing areas ID, has corresponding value in 
        // chosenDestinations reference array.

        let reducedKeywords = [];
        
        if (keywords.length !== undefined) {

            if (keywords.length > 1) {
                reducedKeywords = keywords.filter(word => {
    
                    let metDestination = chosenDestinations.current.find(dest => dest.value === word.area)
                    if (metDestination !== undefined) {
                        return true
                    }
        
                })
            } if (keywords.length === 1) {
                reducedKeywords = keywords;
            }

        }

        // console.log("&&& checkKeywordsOnDestinations returns:", reducedKeywords )
        // console.log("&&& inside checkKeywordsOnDestinations chosenDestinations.current:", chosenDestinations.current)


        return reducedKeywords;
    }

    const keywordsHandler = useMemo(() => (destinations, delay) => {
        /**
         * keywordsHandler works like debounce function: it processes 
         * new destinations immediately, but any next call will be postponed
         * for @delay ms, originally function was build to limit queries
         * to Notion API, and with proper backend it just serves as
         * unnecesary, but usefull sugar.
         */

        let now = new Date().getTime();

        const rearrange = (stateKeywords, newKeywords) => {
            // function takes current @relevantKeywords state and newKeywords
            // and rearrange them to match chosen destinations order

            let allKeywords = stateKeywords.concat(newKeywords);

            if (allKeywords.length !== chosenDestinations.current.length) {
                // there may be passed more keywords than needed at the moment
                // in case when user deletes chosen destination when fetching of
                // it's keyword was still in progress. To avoid showing irrelevant keywords
                // @checkKeywordsOnDestinations function called. 
                allKeywords = checkKeywordsOnDestinations(allKeywords);
            }

            if (allKeywords.length > 1) {
                allKeywords.forEach((element, index) => {
                    let desiredOrder = chosenDestinations.current.findIndex((e) => e.value === element.area);
                    if (desiredOrder === -1) {
                        allKeywords.splice(index, 1)
                    } else {
                        element.index = desiredOrder;
                    }
                })
                
                allKeywords.sort((a, b) => {
                    if (a.index > b.index) {
                        return 1
                    } if (a.index < b.index) {
                        return -1
                    } if (a.index = b.index) {
                        return 0
                    }
                })
                
            } if (allKeywords[0] !== undefined ) {
                allKeywords[0].index = 0;
            }

            return allKeywords;
            
        }

        const queryAndUpdate = (des) => {

            const fetchKeywordsData = async (des) => {
                // console.log("fetchKeywordsData runs to query destination:", des)
                let keywords = await provideKeywords(userData.nocodb_auth, des)
                // console.log("fetchKeywordsData got:", keywords)

                if (chosenDestinations.current !== undefined) {
                    setRelevantKeywords(relevantKeywords => rearrange(relevantKeywords, keywords))
                }

            }

            if (des !== undefined) {
                fetchKeywordsData(des).catch(err => {console.log(err)})
            } if (relevantKeywords.length !== chosenDestinations.current.length) {
                setRelevantKeywords(relevantKeywords => rearrange(relevantKeywords, []))
            }

        }

        if (now - lastCalled.current < delay) {
            keywordsTimeout.current !== null && clearTimeout(keywordsTimeout.current);
            queuedDestinationsToQueryKeywords.current = queuedDestinationsToQueryKeywords.current.concat(destinations);
            keywordsTimeout.current = setTimeout(() => {
                keywordsTimeout.current = null;
                queryAndUpdate(queuedDestinationsToQueryKeywords.current)
                queuedDestinationsToQueryKeywords.current = [];
            }, delay);
            return;
        }

        lastCalled.current = now;
        clearTimeout(keywordsTimeout.current);
        queryAndUpdate(destinations);


    }, [relevantKeywords])

    return (
        <div id="keyWordContainer" className={`${relevantKeywords.length < 1 ? "" : "visible"}`} style={{opacity: `${relevantKeywords.length < 1 ? 0.3 : 1}` }}>
            <span>Кодовые слова</span>
            <ul> 
                {relevantKeywords.map((item, index) => (
                    <li key={`key-${item.word}-word-${index}`}>{item.word}</li>
                ))
                }
                
            </ul>
        </div>
    );
}

export default KeyWordContainer;
