import React, { useState, useContext, useMemo, useCallback, useRef } from 'react';
import { useSearchParams } from "react-router-dom";

import { MainContext } from "../MainContext";
import useCachedDataWithUpdates from "../../services/useCachedDataWithUpdates"
import useSelectOptions from "../../services/useSelectOptions";

import Table from '../commonUI/Table';
import JournalRowMemoised from "./JournalRow";
import RenderOnRole from "../../services/RenderOnRole"
import ActiveBlacklistMemoized from './ActiveBlacklist';
import ActiveDepositsMemoized from "./ActiveDeposits";

const initialArrayDataObject = [];

const initialHiddenColumns = {
    onlyActiveHidden: ["origin_ID", "updated_at", "created_at", "entry_point", "entered_at", "useful_photo", "parking_type", "deposit_id", "created_by"],
    allHidden: ["created_by", "origin_ID", "deposit_id","updated_at", "delete"]
};

const findOutInitialTableView = (roles, idQuery) => {
    if (roles.includes("patrol")) {
        return false;
    } else if (idQuery) {
        return false;
    } else {
        return true;
    }
}


const Journal = () => {
    let [searchParams] = useSearchParams();
    const idQuery = searchParams.get('origin_ID');
    const { siteSettings, userData } = useContext(MainContext);
    const initialShowOnlyActiveState = findOutInitialTableView(userData.roles, idQuery);
    const [blacklistData, setBlacklistData] = useState(initialArrayDataObject);
    const [depositsData, setDepositsData] = useState(initialArrayDataObject);
    const freezeRef = useRef(false);

    const columns = useMemo(() => {
        let cols = [
            {
                Header: 'Назначение',
                accessor: 'dest',
                // disableFilters: true,
            },
            {
                Header: 'Номер или имя',
                accessor: 'number',
            },
            {
                Header: 'Марка',
                accessor: 'brand',
                // disableFilters: true,  
            },
            {
                Header: 'Категория',
                accessor: 'category',
                // disableFilters: true,
            },
            {
                Header: 'Комментарий',
                accessor: 'comment',
            },
            {
                Header: 'Въехал',
                accessor: 'entered',
                disableFilters: true,
            },
            {
                Header: 'Суточный',
                accessor: 'daily',
                disableFilters: true,
            },
            {
                Header: 'Кредит',
                accessor: 'credit',
                disableFilters: true,
            },
            {
                Header: 'Точка доступа',
                accessor: 'entry_point',
            },
            {
                Header: 'Время доступа',
                accessor: 'entered_at',
                // disableFilters: true,
            },
            {
                Header: 'По депозиту c ID:',
                accessor: 'deposit_id',
                disableFilters: true,
            },
            {
                Header: 'Создание',
                accessor: 'created_at',
                // disableFilters: true,
            },
            {
                Header: 'Редакция',
                accessor: 'updated_at',
                // disableFilters: true,
            },
            {
                Header: 'Создатель',
                accessor: 'created_by',
                // disableFilters: true,
                isVisible: false,
            },
            {
                Header: 'origin_ID',
                accessor: 'origin_ID',
                // disableFilters: true,
                isVisible: false,
            },
            {
                Header: 'Удалить',
                accessor: 'delete',
                disableFilters: true,
            },
    
        ]

        if (userData.roles.includes('patrol') || userData.roles.includes('admin')) {
            let creditPropertyIndex = cols.findIndex(col => col.accessor === "credit") + 1;

            cols.splice(creditPropertyIndex, 0,
                {
                    Header: 'Парковка',
                    accessor: 'parking_type',
                    disableFilters: true,
                },
                {
                    Header: 'Фото',
                    accessor: 'useful_photo',
                    disableFilters: true,
                }
            )

        }

        return cols;
    }
    , [userData]);

    const [
        areBrandsLoading,
        allBrandsOptionsRef,
    ] = useSelectOptions({
        siteSettings,
        nocodb_auth: userData.nocodb_auth,
        optionName: "brandsOptions",
    })
    
    const [
        isBlacklistLoading,
    ] = useCachedDataWithUpdates({
        location: "/",
        disabled: userData.roles.includes('patrol'),
        dataSource: "blacklist",
        updateFrequency: 15000,
        cachedDataLifeSpan: 180000,
        onViewSwitchFilterCondition: '(archived,eq,false)',
        sortString: "-updated_at",
        offViewSwitchFilterCondition: undefined,
        altSortString: "-updated_at",
        localFieldToCompareBy: "updated_at_full",
        remoteFieldToCompareBy: "updated_at",
        nocodb_auth: userData.nocodb_auth,
        setTableData: setBlacklistData,
        initialStateObject: initialArrayDataObject,
        loggingEnabled: !!siteSettings?.blacklist_loggingEnabled,
        compareTotalEntries: true,
    });

    const [
        areDepositsLoading,
        depositsDataRef,
        justUsedDepositsRef, 
    ] = useCachedDataWithUpdates({
        location: "/",
        disabled: userData.roles.includes('patrol'),
        dataSource: "deposits",
        updateFrequency: 8000,
        cachedDataLifeSpan: 120000,
        shallowQuery: true,
        onViewSwitchFilterCondition: '(status,eq,Действителен)',
        sortString: "-last_related_update",
        offViewSwitchFilterCondition: undefined,
        altSortString: "-last_related_update",
        localFieldToCompareBy: "last_related_update",
        nocodb_auth: userData.nocodb_auth,
        setTableData: setDepositsData,
        initialStateObject: initialArrayDataObject,
        loggingEnabled: !!siteSettings?.deposits_loggingEnabled,
        compareTotalEntries: true,
    });

    const getJournalRowMemoised = useCallback(({
        row,
        hiddenColumnsLength,
        tableDataRef,
        deletingRowsRef,
        justAlteredRowsRef,
        showOnlyActive,
    }) => {

        return (
            <JournalRowMemoised 
                key={`row-entry-${row.original?.origin_ID}-${row.original?.updated_at_full}-${hiddenColumnsLength}`} 
                row={row}
                allBrandsOptionsRef={allBrandsOptionsRef}
                areBrandsLoading={areBrandsLoading}
                // brandsOptions={brandsOptions}
                // setBrandsOptions={setBrandsOptions}
                tableDataRef={tableDataRef}
                deletingRowsRef={deletingRowsRef}
                justAlteredRowsRef={justAlteredRowsRef}
                justUsedDepositsRef={justUsedDepositsRef}
                depositsDataRef={depositsDataRef}
                canBeMarkedAsEntered={
                    !areDepositsLoading && !isBlacklistLoading
                }
                showOnlyActive={showOnlyActive}
                freezeRef={freezeRef}
            />
        );
        
    }, [depositsDataRef, justUsedDepositsRef, allBrandsOptionsRef, areBrandsLoading, areDepositsLoading, isBlacklistLoading])


    return (
        <Table
            location='/'
            tablePrefix="journal"
            initialArrayDataObject={initialArrayDataObject}
            initialShowOnlyActiveState={initialShowOnlyActiveState}
            columns={columns}
            initialHiddenColumns={initialHiddenColumns}
            hatComponents={
                <RenderOnRole roles={["kpp1", "dezhurniy", "kpp3", "admin"]}>
                    <ActiveBlacklistMemoized 
                        usersRoles={userData.roles}
                        blacklistData={blacklistData}
                        isLoading={isBlacklistLoading}
                    />
                    <ActiveDepositsMemoized 
                        depositsData={depositsData}
                        isLoading={areDepositsLoading}
                    />
                </RenderOnRole>
            }
            toggle={
                {
                    enabled: true,
                    showOnlyActiveLabel: "Только активные заявки:",
                    showAllLabel: "Весь журнал:",
                    // onlyHat: true,
                }
            } 
            freezeRef={freezeRef}
            dataSettings={
                {
                    // freeze: freezeJournalUpdates,
                    initialPageSize: 20,
                    altInitialPageSize: 10,
                    updateFrequency: 5000,
                    cachedDataLifeSpan: 120000,
                    onViewSwitchFilterCondition: "(is_active,eq,true)",
                    offViewSwitchFilterCondition: undefined,
                    sortString:"-updated_at",
                    altSortString: "-updated_at",
                    localFieldToCompareBy: "updated_at_full",
                    remoteFieldToCompareBy: "updated_at",
                    keepTableSettings: true,
                    syncRecordsPerPage: true,
                }
            }
            returnMemoizedTableRowComponent={getJournalRowMemoised}
            noEntriesMessage={'Нет подходящих заявок'}
            idQuery={idQuery}
        />
    );

}
 
export default Journal;
