import PropTypes from 'prop-types'
import UserService from "./UserService";

const RenderOnRole = ({ roles, fallbackElement = null, children }) => {
  return UserService.hasRole(roles) ? children : fallbackElement
}

RenderOnRole.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default RenderOnRole;