import React, { useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';

import { focusMainSearch } from "../../services/littleHelpers";
import useCurrentSizes from "../../services/useCurrentSizes";
import NavigationLinksMenu from "./NavigationLinksMenu"

const navigateApp = (e) => {
    const navContainer = document.getElementById("main-navigation");
    const navLinkNumber = Number.parseInt(e.key) - 1;

    if (navLinkNumber < navContainer.children.length) {
        if (navContainer.children[navLinkNumber].classList.contains("nav-dummy-div")) {
            navContainer.children[navLinkNumber + 1].click()
        } else {
            navContainer.children[navLinkNumber].click()
        }
    }
}

const submitAddToJournalForm = (e) => {
    // console.log("e inside submitAddToJournalForm",e);

    if (window.location.pathname === "/add") {
        if (e.keyCode === 13) {
            // console.log("keydown event listener registered enter key pressed", e) 
            if (e.target?.offsetParent?.attributes?.id?.nodeValue !== "main-navigation") {
                e.preventDefault();
                // console.log("preventDefault outside of #main-navigation element") 

            }
        } if (e.ctrlKey && e.keyCode === 13) {
            // console.log("keydown event listener registered Ctrl + Enter key combination pressed", e) 
            document.getElementById('add-to-journal-form').requestSubmit(document.getElementById('main-submit'));
        }
    }
}

const moveFocusInTable = async (e, prevFocusedElementRef, justFocusedElementRef, mainSearchId) => {
    
    if (window.location.pathname === "/") {
        
        let tableElement = document.querySelector('[id*="-table-entity"]');
        let tableFooter = document.querySelector('[id*="-table-fake-footer"]');
        let pageControls = document.querySelector('[id*="-pagination-controls"]');

        switch (e.key) {

            case "ArrowDown":

                if (tableElement.contains(justFocusedElementRef.current)) {

                    if (justFocusedElementRef.current.attributes?.id?.textContent?.includes("column")) {

                        let columnType = justFocusedElementRef.current.attributes?.id?.textContent?.replace(/.*-id-/, "");
                        let nextRow = tableElement.querySelector('tr[class*="-entry"]');
                        let targetCell = nextRow.getElementsByClassName(`cell-in-${columnType}`)[0];
                        let targetInput = targetCell.getElementsByTagName("input")[0] || targetCell.getElementsByTagName("textarea")[0];

                        targetInput && targetInput.focus();

                    } else if (justFocusedElementRef.current.getAttribute("aria-expanded") !== "true") {


                        let columnType = justFocusedElementRef.current.closest("td").className.replace(/cell-in-/, '').replace(/\s.+$/, "");
                        let thisRow = justFocusedElementRef.current.closest('[class*="-entry"]');
                        let folowingRow = thisRow.nextElementSibling;

                        if (folowingRow !== null) {
                            let targetCell = folowingRow.getElementsByClassName(`cell-in-${columnType}`)[0];
                            let targetInput = targetCell.getElementsByTagName("input")[0] || targetCell.getElementsByTagName("textarea")[0]; 
                            
                            targetInput.focus();

                        } else if (pageControls !== null) {
                            let button = pageControls.querySelector(".button:not(:disabled)");
                            
                            prevFocusedElementRef.current = justFocusedElementRef.current;
                            button.focus();

                        } else {
                            let firstRow = tableElement.querySelector('tr[class*="-entry"]');

                            let targetCell = firstRow.getElementsByClassName(`cell-in-${columnType}`)[0];
                            let targetInput = targetCell.getElementsByTagName("input")[0] || targetCell.getElementsByTagName("textarea")[0];
                            
                            targetInput.focus();
                        }


                    } 

                }

                break

            case "ArrowUp":

                if ( tableElement.contains(justFocusedElementRef.current) || tableFooter.contains(justFocusedElementRef.current) ) {
                    e.preventDefault();

                    if (pageControls?.contains(justFocusedElementRef.current)) {
                        
                        prevFocusedElementRef.current.focus();
                        
                    } else if (justFocusedElementRef.current.attributes?.id?.textContent?.includes("column")) {

                        break

                    } else if (justFocusedElementRef.current.getAttribute("aria-expanded") !== "true") {

                        let columnType = justFocusedElementRef.current.closest("td").className.replace(/cell-in-/, '').replace(/\s.+$/, "");
                        let thisRow = justFocusedElementRef.current.closest('tr[class*="-entry"]');
                        let previousRow = thisRow.previousElementSibling;

                        if (previousRow !== null) {

                            let targetCell = previousRow.getElementsByClassName(`cell-in-${columnType}`)[0];
                            let targetInput = targetCell.getElementsByTagName("input")[0] || targetCell.getElementsByTagName("textarea")[0]; 
                            
                            targetInput.focus();

                        } else {
                            
                            let columnType = justFocusedElementRef.current.closest("td").className.replace(/cell-in-/, '').replace(/\s.+$/, "");
                            let headerSearch = document.getElementById(`column-filter-id-${columnType}`);
                            
                            if (headerSearch && headerSearch?.id !== "column-filter-id-number") {
                            
                                headerSearch.focus(); 
                                headerSearch.select();

                                break;
                            
                            } else {
                                focusMainSearch(null, 0, mainSearchId, window.location.pathname);
                            }

                        }

                    }
                    

                }

            break
            
        } 

    }
    
}

const scrollToSeeElementClearly = (element) => {
    let el = element;

    if (element.matches('[class*="select__input"]')) {
        el = element.closest('[class$="-container"]')
    } else if (element.id === "setShowOnlyActiveSwitch") {
        el = element.closest('label')
    }

    const rect = el.getBoundingClientRect();
    let desiredOffset = Math.floor(rect.height * 2.618);
    let scrollByX = 0;
    let scrollByY = 0;

    if (rect.x < desiredOffset) {

        scrollByX = Math.floor((desiredOffset - rect.x) * -1);

    } else if (rect.x + rect.width + desiredOffset > window.innerWidth) {

        scrollByX = Math.floor((window.innerWidth - (rect.x + rect.width + desiredOffset)) * -1);

    } 
    
    if (rect.y < desiredOffset) {

        scrollByY = Math.floor((desiredOffset - rect.y) * -1);

    } else if (rect.y + rect.height + desiredOffset > window.innerHeight) {

        scrollByY = Math.floor((window.innerHeight - (rect.y + rect.height + desiredOffset)) * -1);

    }

    if (scrollByX !== 0 || scrollByY !== 0) {
        window.scrollBy(scrollByX, scrollByY);
    }

}

const clearTableFilters = (e) => {
    e.preventDefault()
    console.log("clearTableFilters")
    const refreshButton = document.querySelector('button.refresh-columns');
    if (refreshButton) {
        refreshButton.click()
    }
}


const Navigation = () => {
    const location = useLocation();
    const mainSearchId = location.pathname === "/pedestrians" || location.pathname === "/contacts" ? "name" : 'number';
    const [viewportWidth, viewportHeight] = useCurrentSizes();
    const prevFocusedElementRef = useRef(undefined);
    const justFocusedElementRef = useRef(undefined);
    const navContainer = useRef();

    useEffect(() => {

        const setCurrentFocusedElement = (focusin_event) =>{
            justFocusedElementRef.current = focusin_event.target
            scrollToSeeElementClearly(focusin_event.target)
        }

        document.addEventListener('focusin', setCurrentFocusedElement)

        return () => { document.removeEventListener('focusin', setCurrentFocusedElement) }

    }, []);
    
    useHotkeys('ctrl+enter, enter',  (e) => submitAddToJournalForm(e), {keydown: true, enableOnTags: ["INPUT", "TEXTAREA", "SELECT"]} )
    useHotkeys('ctrl+1, ctrl+2, ctrl+3, ctrl+4, ctrl+5, ctrl+6, ctrl+7, ctrl+8, ctrl+9',  (e) => navigateApp(e), {keydown: true, enableOnTags: ["INPUT", "TEXTAREA", "SELECT"]} )
    useHotkeys('ctrl+f, ctrl+а',  (e) => focusMainSearch(e, 350, mainSearchId, location.pathname), {keydown: true, enableOnTags: ["INPUT", "TEXTAREA", "SELECT"]} )
    useHotkeys('*',  (e) => moveFocusInTable(e, prevFocusedElementRef, justFocusedElementRef, mainSearchId), {keyup: true, enableOnTags: ["INPUT", "TEXTAREA", "SELECT"]} )
    useHotkeys(
        'shift+alt+C, alt+shift+C, shift+alt+c, alt+shift+c, shift+alt+С, alt+shift+С, shift+alt+с, alt+shift+с',
        (e) => clearTableFilters(e),
        {keyup: true, enableOnTags: ["INPUT", "TEXTAREA", "SELECT"]} )


    // console.log('Navigation renders')
    return (
        <>
            <div id="main-navigation-container">
                <nav id="main-navigation" ref={navContainer} >
                    <NavigationLinksMenu
                        pathname={location.pathname}
                        navContainerRef={navContainer}
                        viewportWidth={viewportWidth}
                    />
                </nav>
            </div>
        </>

    )

}

export default Navigation;
