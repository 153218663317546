import React from 'react';
import UserService from '../services/UserService';

const NotWelcomePage = () => {

    return (
        <>
            <div id="welcome-container">
                <h1>Ресурс не существует или доступ ограничен</h1>
                <p className="username">Вы вошли как <span>{UserService.getUsername()}</span></p>
                <button 
                    className='button logout'
                    onClick={UserService.doLogout}
                >
                    Выйти из учётной записи
                </button>
            </div>
        </>
    );
}

export default NotWelcomePage;