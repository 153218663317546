import React, { useContext, useMemo, useCallback } from 'react';
import { useSearchParams } from "react-router-dom";

import { MainContext } from "../MainContext";

import Table from '../commonUI/Table';
import PedestrianRowMemoised from "./PedestrianRow";

const initialArrayDataObject = [];
const initialHiddenColumns = {
    onlyActiveHidden: ['origin_ID', "updated_at", "created_at", "archived"],
    allHidden: ['origin_ID', "updated_at"]
};

const findOutInitialTableView = (idQuery) => {
    if (idQuery) {
        return false;
    } else {
        return true;
    }
}

const LocalPedestrians = () => {
    let [searchParams] = useSearchParams();
    const idQuery = searchParams.get('origin_ID');
    const { userData } = useContext(MainContext);

    const columns = useMemo(() => {
        let cols = [
            {
                Header: 'Объект',
                accessor: 'dest',
            },
            {
                Header: 'Имя',
                accessor: 'name',
            },
            {
                Header: 'Комментарий',
                accessor: 'comment',
            },
            {
                Header: 'Документы',
                accessor: 'docs',
                disableFilters: true,
            },
            {
                Header: 'Архив',
                accessor: 'archived',
                disableFilters: true,
            },
            {
                Header: 'Создание',
                accessor: 'created_at',
            },
            {
                Header: 'Редакция',
                accessor: 'updated_at',
            },
            {
                Header: 'origin_ID',
                accessor: 'origin_ID',
                // disableFilters: true,
            }
        ]

        // if (userData.roles.includes('admin')) {
        //     let docsPropertyIndex = cols.findIndex(col => col.accessor === "docs") + 1;

        //     cols.splice(docsPropertyIndex, 0,
                // {
                //     Header: 'Архив',
                //     accessor: 'archived',
                //     disableFilters: true,
                // },
        //     )

        // }

        return cols;
    }
    , [userData]);

    const getlocalVehicleRowMemoised = useCallback(({
        row,
        hiddenColumnsLength,
        deletingRowsRef,
        justAlteredRowsRef,
        setEditedEntryID,
    }) => {

        return (
            <PedestrianRowMemoised
                key={`row-entry-${row.original.origin_ID}__${row.original.updated_at && row.original.updated_at.replace(/\s/g, '_')}__${hiddenColumnsLength}`} 
                preparedRow={row}
                deletingRowsRef={deletingRowsRef}
                justAlteredRowsRef={justAlteredRowsRef}
                setEditedEntryID={setEditedEntryID}
            />
        );
        
    }, [])

    return (
        <Table
            location='/pedestrians'
            tablePrefix="localPedestrians"
            initialArrayDataObject={initialArrayDataObject}
            initialShowOnlyActiveState={findOutInitialTableView(idQuery)}
            columns={columns}
            initialHiddenColumns={initialHiddenColumns}
            toggle={
                {
                    enabled: true,
                    showOnlyActiveLabel: "Пешеходы к допуску:",
                    showAllLabel: "Все пешеходы:",
                    onlyHat: true,
                }
            } 
            returnMemoizedTableRowComponent={getlocalVehicleRowMemoised}
            dataSettings={{
                initialPageSize: 10,
                altInitialPageSize: 10,
                updateFrequency: 5000,
                cachedDataLifeSpan: 180000,
                onViewSwitchFilterCondition: '(archived,eq,false)',
                offViewSwitchFilterCondition: undefined,
                sortString:"-updated_at",
                altSortString: "-updated_at",
                localFieldToCompareBy: "updated_at_full",
                remoteFieldToCompareBy: "updated_at",
                keepTableSettings: true,
                syncRecordsPerPage: true,
            }}
            idQuery={idQuery}
            noEntriesMessage={"Подходящие пешеходы не найдены"}
            keepTableSettings={false}
        />
    );

}

export default LocalPedestrians;