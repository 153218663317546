import React, { useEffect, useState } from "react";
import { 
  Routes, 
  Route
 } from "react-router-dom";
import './App.scss';

import { MainContext } from './components/MainContext'
import NotWelcomePage from './components/NotWelcomePage'
import Dev from "./components/settings/dev/Dev";
import AddToJournalForm from "./components/add-to-journal/AddToJournalForm";
import Navigation from "./components/navigation/Navigation";
import Settings from "./components/settings/Settings";
import History from "./components/settings/History";
import VehicleBrands from "./components/settings/VehicleBrands/VehicleBrands";
import Journal from "./components/journal/Journal";
import LocalPedestrians from "./components/pedestrians/LocalPedestrians";
import Accounting from "./components/accounting/Accounting";
import AllDeposits from "./components/accounting/AllDeposits/AllDeposits";
import MainSearch from "./components/main-search/MainSearch";

import UserService from "./services/UserService";
import RenderOnRole from "./services/RenderOnRole";
import LocalVehicles from "./components/local-vehicles/LocalVehicles";
import BlacklistPage from "./components/blacklist/BlacklistPage";
import Contacts from "./components/contacts/Contacts";


const defaultSiteSettings = {
  theme: "light",
  tooltips: true,
  refreshFrequency: 60 * 60 * 1000,
  journal_loggingEnabled: false,
  debt_loggingEnabled: false,
  reports_loggingEnabled: false,
  blacklist_loggingEnabled: false,
  deposits_loggingEnabled: false,
  localVehicles_loggingEnabled: false,
  localPedestrians_loggingEnabled: false,
  allBrands_loggingEnabled: false,
}

export const allRoles = ['dev', 'admin', 'dezhurniy', 'kpp1', 'kpp2', 'kpp3', 'starshoi', 'patrol', 'accountant'];
export const allSecurityRoles = ['dev', 'admin', 'dezhurniy', 'kpp1', 'kpp2', 'kpp3', 'starshoi', 'patrol'];

function App() {
  const [siteSettings, setSiteSettings] = useState(
    window.localStorage.getItem("siteSettings") !== "null" &&
    window.localStorage.getItem("siteSettings") !== null ?
    JSON.parse(window.localStorage.getItem("siteSettings")) :
    defaultSiteSettings
  )
  const [userData, setUserData] = useState(() => {
    let token = UserService.getParsedToken();
    
    // console.log("token recieved in App", token)

    let data = {
      username: token?.preferred_username,
      family_name: token?.family_name,
      given_name: token?.given_name,
      nocodb_auth: token?.nocodb_auth,
      // roles: token?.resource_access?.[process.env.REACT_APP_KEYCLOAK_CLIENT]?.roles,
    }

    if (window.location.origin === "https://service.landshaft.life" ) {
      data.roles = token?.resource_access?.[process.env.REACT_APP_KEYCLOAK_CLIENT_BASE]?.roles
    } else {
      data.roles = token?.resource_access?.[process.env.REACT_APP_KEYCLOAK_CLIENT_RESERVE]?.roles
    }
      
    return data;
  })



  const contextValue = {
    siteSettings,
    setSiteSettings,
    userData,
    setUserData
  }
  
  useEffect(() => {

    if (siteSettings?.theme === "dark") {
      trans();
      document.documentElement.setAttribute('data-theme', 'dark')
    } if (siteSettings?.theme === "light") {
      trans();
      document.documentElement.setAttribute('data-theme', 'light')
    }
    
    window.localStorage.setItem("siteSettings", JSON.stringify(siteSettings))
  }, [siteSettings]);


  let trans = () => {
    document.documentElement.classList.add('transition');
    window.setTimeout(() => {
        document.documentElement.classList.remove('transition');
    }, 1000)
  }

  return (
    <MainContext.Provider value={contextValue}>
      <Navigation />
      <Routes>
        <Route path="*" element={<NotWelcomePage />} />
        <Route 
          path="/add"
          element={
            <RenderOnRole roles={ ["admin", "dezhurniy"] } fallbackElement={<NotWelcomePage />}>
              <AddToJournalForm/>
            </RenderOnRole>
          }
        />
        <Route 
          path={"/"}
          element={
            <RenderOnRole roles={ allSecurityRoles } fallbackElement={<NotWelcomePage />}>
              <Journal />
            </RenderOnRole>
          }
        />
        <Route 
          path="/pedestrians"
          element={
            <RenderOnRole roles={ ["admin", "kpp2", "kpp1", "patrol", "analyst"] } fallbackElement={<NotWelcomePage />}>
              <LocalPedestrians/>
            </RenderOnRole>
          }
        />
        <Route 
          path="/local-vehicles"
          element={
            <RenderOnRole roles={ allSecurityRoles } fallbackElement={<NotWelcomePage />}>
              <LocalVehicles />
            </RenderOnRole>
          }
        />
        <Route 
          path="/blacklist"
          element={
            <RenderOnRole roles={ allSecurityRoles } fallbackElement={<NotWelcomePage />}>
              <BlacklistPage />
            </RenderOnRole>
          }
        />
        <Route 
          path="/settings"
          element={
            <RenderOnRole roles={ allRoles } fallbackElement={<NotWelcomePage />}>
              <Settings />
            </RenderOnRole>
          }
        />
        <Route 
          path="/accounting"
          element={
            <RenderOnRole roles={ ['admin', 'accountant'] } fallbackElement={<NotWelcomePage />}>
              <Accounting />
            </RenderOnRole>
          }
        />
        <Route 
          path="/accounting/deposits"
          element={
            <RenderOnRole roles={ ['admin', 'accountant'] } fallbackElement={<NotWelcomePage />}>
              <AllDeposits />
            </RenderOnRole>
          }
        />
        <Route 
          path="/settings/add-to-journal-form-history"
          element={
            <RenderOnRole roles={ ["admin", "dezhurniy"] } fallbackElement={<NotWelcomePage />}>
              <History />
            </RenderOnRole>
          }
        />
        <Route 
          path="/settings/vehicle-brands"
          element={
            <RenderOnRole roles={ allSecurityRoles } fallbackElement={<NotWelcomePage />}>
              <VehicleBrands />
            </RenderOnRole>
          }
        />
        <Route 
          path="dev"
          element={
            <RenderOnRole roles={ allRoles /*["dev"]*/ } fallbackElement={<NotWelcomePage />}>
              <Dev />
            </RenderOnRole>
          }
        />
        <Route 
          path="contacts"
          element={
            <RenderOnRole roles={ allRoles } fallbackElement={<NotWelcomePage />}>
              <Contacts />
            </RenderOnRole>
          }
        />
        <Route 
          path="search"
          element={
            <RenderOnRole roles={ ["admin", "dezhurniy", "patrol", "analyst"] } fallbackElement={<NotWelcomePage />}>
              <MainSearch />
            </RenderOnRole>
          }
        />
      </Routes>
      <footer>
          <div id="sticky-footer">
            <p>👮 2022</p>
          </div>
      </footer>
    </MainContext.Provider>
  );
}

export default App;