import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import UserService from '../../../services/UserService';
import { MainContext } from '../../MainContext';
import RenderOnAnonymous from '../../../services/RenderOnAnonymous';
import RenderOnAuthenticated from '../../../services/RenderOnAuthenticated';
import RenderOnRole from '../../../services/RenderOnRole';
import { updateValues } from "../../../services/CRUDJournalData";
import { makeRequest, requestRowsData } from "../../../services/CRUDJournalData"
import { fetchWithTimeout, responseStreamReader, notifySecurityChat } from "../../../services/littleHelpers"
import { provideActiveDeposits, provideAreas, provideBrands, provideDeposits, provideJournalEntries } from '../../../services/ProvideDataObjects';



const Dev = () => {
    const { siteSettings, userData } = useContext(MainContext);
    let controller = useRef();
    

    const gimmeSome = async () => {
        if (controller.current) {
            controller.current.abort();
        }
        controller.current = new AbortController();

        console.time('gimmeSome');
        console.log(
            await provideJournalEntries({
                auth_token: userData.nocodb_auth, 
                fields: "Id,status,area_name",
                currentViewFilterCondition: "(is_active,eq,true)",
                pageNo: 0,
                recordsLimit: 10,
                sortString: "-updated_at",
                controller: controller.current,
            })
        )
        console.timeEnd('gimmeSome');
        controller.current = undefined;
    }

    const apitest = async() => {

        let rowsData = await requestRowsData({
            auth_token: userData.nocodb_auth,
            // endpoint: "journal/views/journal-human",
            endpoint: "journal",
            hasManyToManyfields: "id",
            hasManyToMany: " ",
            totalCount: 10,
            sortString: "-updated_at",
            fields: ""
        })

        console.log(rowsData)

    }
    /*
    const uploadFile = async () => {
        let formData = new FormData();

        fetch('https://unsplash.it/500/500')
        // .then(res => res.buffer()) // Gets the response and returns it as a blob
        .then(res => res.blob()) // Gets the response and returns it as a blob
        // .then(buffer => {
            .then( async (blob) => {
                // console.log("buffer", buffer)
                console.log("blob", blob)
            let buffer = await blob.arrayBuffer()
            // Here's where you get access to the blob
            // And you can use it for whatever you want
            // Like calling ref().put(blob)

            // Here, I use it to make an image appear on the page
            // let objectURL = URL.createObjectURL(blob);
            // let myImage = new Image();
            // myImage.src = objectURL;
            // document.getElementById('myImg').appendChild(myImage)
            // formData.append("document", blob, "blob-name")
            console.log("Using buffer this time", buffer)

            formData.append("file", buffer);
            // formData.append("file", blob);
            formData.append('json', JSON.stringify({"api":"xcAttachmentUpload","project_id":"more_then_land_z6gg","dbAlias":"db","args":{}}));
        }).then(async () => {
            
            console.log(`formData.get("file")`, formData.get("file"))
            const responseOnUpdate = await fetchWithTimeout(
                `http://localhost:8080/api/v1/db/storage/upload?path=pedestrian`,
                {
                    timeout: 30000,
                    method: "POST",
                    headers: {
                    //   "Accept": "application/json",
                    //   "Content-Type": "multipart/form-data",
                      "xc-auth": userData.nocodb_auth,
                    },
                    body: formData,
                }
            ).catch((err) => {
                console.error(err)
                return 0;
            });
    
            console.log("responseOn file upload", responseOnUpdate)
            let jsonResponse = await responseOnUpdate.json();
            console.log(jsonResponse)
            
            // let responseOnUpdateBody = await responseStreamReader(responseOnUpdate);
            // let responseOnUpdateBodyObject = JSON.parse(responseOnUpdateBody);
            
            // console.log("responseOn file upload Object", responseOnUpdateBodyObject)
            return jsonResponse;
        }).then(async (fileData) => {
            // let newFile = [];
            // newFile.push(fileData)
            // console.log("fileData", fileData)
            // http://localhost:8080/api/v1/db/data/noco/More%20Then%20Land/test-table
            const responseOnUpdate = await fetchWithTimeout(
                `http://localhost:8080/api/v1/db/data/noco/More%20Then%20Land/pedestrians/1`,
                {
                    timeout: 30000,
                    method: "PATCH",
                    headers: {
                      "Accept": "application/json",
                      "Content-Type": "application/json",
                      "xc-auth": userData.nocodb_auth,
                    },
                    body: JSON.stringify({file: JSON.stringify(fileData)}),
                }
            ).catch((err) => {
                console.error(err)
                return 0;
            });

            console.log("responseOnPedestrian update", responseOnUpdate)
    
            let responseOnUpdateBody = await responseStreamReader(responseOnUpdate);
            let responseOnUpdateBodyObject = JSON.parse(responseOnUpdateBody);
    
            console.log("responseOnPedestrian update Object", responseOnUpdateBodyObject)
            
        })
        // multipart/form-data

        // const data = {
        //     files: "",
        //     json: ""
        // }

    }
    */

    const updateTest = async () => {
        console.log(
            await updateValues({
                auth_token: userData.nocodb_auth,
                
                values: [{
                    targetProperty: "number",
                    rowID: "11087",
                    newValue: "test3updateBatch",
                },
                {
                    targetProperty: "comment",
                    rowID: "11087",
                    newValue: "test3updateBatch",
                },
                {
                    targetProperty: "daily",
                    rowID: "11087",
                    newValue: false,
                }]
            }).catch(err => {return err})
        )
    }

    const notifyChatTest = async () => {
        let cell = {
            row: {
                original: {
                    dest: [
                        {name: "test-area-name"}
                    ]
                }
            }
        };

        notifySecurityChat(cell, [{link:"linkURL"}])
    }

    
    return (
        <>
            <div id="welcome-container">
                <h1>Доступ органичен</h1>
                <p className="username">Сей раз в личине <span>{UserService.getUsername()}</span></p>
                <button 
                    className='button logout'
                    onClick={UserService.doLogout}
                >
                    Выйти из учётной записи
                </button>
                <button 
                    className='button logout'
                    onClick={() => console.log("%cUserService.getToken()", "color:red", UserService.getParsedToken())}
                >
                    getParsedToken
                </button>
                <button 
                    className='button logout'
                    onClick={() => console.log("%cUserService.getToken()", "color:red", UserService.getParsedIdToken())}
                >
                    getParsedIdToken
                </button>
                <button 
                    className='button logout'
                    onClick={() => console.log("userData", userData)}
                >
                    userData
                </button>

                <button 
                    className='button logout'
                    onClick={gimmeSome}
                >
                    gimmeSome
                </button>

            </div>
        </>
    );
}

export default Dev;
