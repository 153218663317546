import { provideBrands, provideAreas } from "./ProvideDataObjects";
import { useState, useEffect, useRef, useCallback } from "react";

const useSelectOptions = ({
    optionName,
    currentViewFilterCondition,
    siteSettings,
    nocodb_auth,
    valueToTriggerRecall,
    enrichOptionsWithDeptorsData
}) => {

    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const allOptionsRef = useRef(options);
    const abortControllerRef = useRef();
    const inStorageName = `${optionName}=${currentViewFilterCondition ? currentViewFilterCondition : "all"}`
    
    const fetchAndProcessOptions = useCallback( async (controller, enrichOptions) => {
        setIsLoading(true)
        let tempOptions;
        if (optionName === "destinationOptions") {
            tempOptions = await provideAreas({
                auth_token: nocodb_auth,
                fields: "Id,area_name,status",
                currentViewFilterCondition,
                controller: controller,
            });
        } if (optionName === "brandsOptions") {
            tempOptions = await provideBrands({
                auth_token: nocodb_auth,
                controller: controller,

            });
        }
        
        if (!!enrichOptions && tempOptions !== undefined) {
            
            Object.keys(enrichOptions).forEach(debtor => {

                let d = tempOptions?.find(option => {
                    return option.value.toString() === debtor
                });

                d.credit_count = enrichOptions[`${debtor}`];
            });

        }

        tempOptions !== undefined && setOptions(tempOptions);

        allOptionsRef.current = tempOptions;
        window.localStorage.setItem(inStorageName, JSON.stringify({
            arrayOfOptions: tempOptions,
            dateOfInsertion: new Date()
        }))
        setIsLoading(false)
    }, [enrichOptionsWithDeptorsData, currentViewFilterCondition, inStorageName, nocodb_auth, optionName] )


    useEffect(() => {
        abortControllerRef.current = new AbortController();

        (async () => {
    
            if (!window.localStorage.getItem(inStorageName)) {

                await fetchAndProcessOptions(abortControllerRef.current, enrichOptionsWithDeptorsData);
            } else {
                let tempData = JSON.parse(window.localStorage.getItem(inStorageName));
                
                if (
                    Date.now() - Date.parse(tempData.dateOfInsertion) < siteSettings.refreshFrequency
                        &&
                    tempData.arrayOfOptions && tempData.arrayOfOptions !== "undefined"
                ) {
                    setOptions(tempData.arrayOfOptions);
                    allOptionsRef.current = tempData.arrayOfOptions;
                } else {
                    await fetchAndProcessOptions(abortControllerRef.current, enrichOptionsWithDeptorsData);
                }
            }
        
        
        })()


        return () => {
            abortControllerRef.current.abort();
        }

    }, [valueToTriggerRecall, fetchAndProcessOptions, inStorageName, siteSettings.refreshFrequency]);
    

    // console.log("%cuseSelectOptions returns:", "color:red",
    //     isLoading,
    //     allOptionsRef,
    //     options,
    //     // setOptions,
    // )

    return [
        isLoading,
        allOptionsRef,
        options,
        setOptions,
    ]


}

export default useSelectOptions;