import React, { useState, useContext } from 'react';
import { TextInput, 
    ToggleRowSelection, 
    DeleteRowButton
} from "../../inputElements";
import { MainContext } from '../../MainContext';
import { allSecurityRoles } from '../../../App';

const BrandRow = ({ preparedRow, deletingRowsRef, justAlteredRowsRef, tableDataRef, selectedFlatRows, previouslySelectedRowRef}) => {
    const { siteSettings } = useContext(MainContext);
    const [rowExists, setRowExists] = useState(true);


    const cellRenderFunction = (cell) => {

        if (cell.column.id === "common_brand") {

            return(
                <TextInput
                    cell={cell}
                    targetProperty={'brand'}
                    targetEndpoint="brands"
                    allowedRoles={allSecurityRoles}
                    customDisablingFunction={() => {return false}}
                />
            );

        } else if (cell.column.id === "delete") {
            
            return <DeleteRowButton
                cell={cell}
                deletingRowsRef={deletingRowsRef}
                justAlteredRowsRef={justAlteredRowsRef}
                tableDataRef={tableDataRef}
                setRowExists={setRowExists}
                target={'brand'}
            />

        } else if (cell.column.id === "selection") {

            return (
                <ToggleRowSelection
                    cell={cell}
                    selectionLimit={2}
                    selectedFlatRows={selectedFlatRows}
                    previouslySelectedRowRef={previouslySelectedRowRef}
                    customTitle={siteSettings.tooltips ? "Выбрать для объединения" : ""}
                />
            );

        }
        

        return cell.render('Cell');

    }


    return (
        <>
            <tr 
                key={`row-updated-at-${preparedRow?.original?.updated_at_full}-${rowExists && "exist"}`}
                id={`brand-entry-id-${preparedRow.original.origin_ID}`}
                className={`brand-entry ${!rowExists ? "deleted" : ""}`}
                {...preparedRow.getRowProps()}
            >
                {
                    preparedRow.cells.map((cell, i) => (
                        <td 
                            className={`cell-in-${cell.column.id} boxProperty`} 
                            key={`${cell.row.original.origin_ID}-${cell.column.header}`} 
                            {...cell.getCellProps()}
                        >
                            { cellRenderFunction(cell) }
                        </td>
                    ))
                }
            </tr>
        </>
    );
}


function rowsPropsAreEqual(prevRow, nextRow) {
    
    if (prevRow.preparedRow.original.origin_ID === nextRow?.preparedRow?.original?.origin_ID) {
        
        let nextSelectedRowsIds = nextRow.selectedFlatRows.reduce((prev, current) => {
            
            return [...prev, {
                id: current?.id,
                blacklist_count: current?.blacklist_count,
                local_vehicles_count: current?.local_vehicles_count,
                journal_count: current?.journal_count,
            }]

        }, [])
    
        let prevSelectedRowsIds = prevRow.selectedFlatRows.reduce((prev, current) => {
            
            return [...prev, {
                id: current?.id,
                blacklist_count: current?.blacklist_count,
                local_vehicles_count: current?.local_vehicles_count,
                journal_count: current?.journal_count,
            }]

        }, [])

        if (JSON.stringify(nextSelectedRowsIds) === JSON.stringify(prevSelectedRowsIds)) {
            return true
        }

    }
    
    return false

  }

const BrandRowMemoised = React.memo(BrandRow, rowsPropsAreEqual)

export default BrandRowMemoised;